<template>
  <el-main>
    <el-form class="el-form-search" label-width="90px">
      <el-form-item label="订单号：">
        <el-input size="small" v-model="searchForm.order_sn"></el-input>
      </el-form-item>
      <el-form-item label="结算状态：">
        <el-select v-model="searchForm.status" size="small" placeholder="请选择">
          <el-option label="全部" value=""></el-option>
          <el-option label="未付款" :value="0"></el-option>
          <el-option label="已付款" :value="1"></el-option>
          <el-option label="待结算" :value="2"></el-option>
          <el-option label="已结算" :value="3"></el-option>
          <el-option label="无效订单" :value="4"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="商户标识：">
        <el-input size="small" v-model="searchForm.sid"></el-input>
      </el-form-item>
      <el-form-item label="活动名称：">
        <el-select v-model="searchForm.act_id" size="small" placeholder="请选择">
          <el-option v-for="(item, index) in category" :key="index" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="下单时间：">
        <el-date-picker size="small" v-model="searchForm.start_time" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
      </el-form-item>
      <el-form-item label=" " label-width="20px">
        <el-button type="primary" size="small" @click="search">搜索</el-button>
        <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="act_name" label="活动名称" align="center"></el-table-column>
      <el-table-column prop="order_sn" label="订单编号" align="center"></el-table-column>
      <el-table-column prop="order_title" label="商品名称" align="center" width="300"></el-table-column>
      <el-table-column prop="sid" label="商户标识" align="center"></el-table-column>
      <el-table-column prop="pay_price" label="付费金额" align="center">
        <template v-slot="{ row }">{{ row.pay_price }}</template>
      </el-table-column>
      <el-table-column prop="jtk_share_rate" label="平台佣金比例" align="center">
        <template v-slot="{ row }">{{ parseFloat(row.jtk_share_rate * 100).toFixed(2) }}%</template>
      </el-table-column>
      <el-table-column prop="jtk_share_fee" label="预估收益" align="center">
        <template v-slot="{ row }">￥{{ row.jtk_share_fee }}</template>
      </el-table-column>
      <el-table-column prop="is_repair_need" label="是否需要修复 " align="center">
        <template v-slot="{ row }">
          <span :style="row.is_repair_need ? { color: 'red', fontWeight: 'bold' } : ''">{{ row.is_repair_need ? '是' : '否' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态" align="center">
        <template v-slot="{ row }">{{ row.status == 0 ? '未付款' : row.status == 1 ? '已付款' : row.status == 2 ? '待结算' : row.status == 3 ? '已结算' : '无效订单' }}</template>
      </el-table-column>
      <el-table-column prop="create_time" label="下单时间" align="center"></el-table-column>
      <el-table-column prop="pay_time" label="付款时间" align="center"></el-table-column>
      <el-table-column label="操作" align="center" fixed="right" width="230">
        <template v-slot="{ row }">
          <el-button @click="setCommission(row.id, 1)" type="text">手动分佣（慎用）</el-button>
          <el-button @click="setCommission(row.id, 2)" type="text">手动分佣2（慎用）</el-button>
          <el-button @click="setEncourage(row.id)" type="text">手动激励（慎用）</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
    <el-dialog title="手动分佣" :visible.sync="showSetCommission" width="650px">
      <el-form ref="form" label-width="90px">
        <el-form-item label="sn">
          <el-input v-model="sn"></el-input>
        </el-form-item>
        <el-form-item label="类型：" v-if="SetCommission_type == 1">
          <el-select v-model="product_type" placeholder="请选择">
            <el-option label="话费" :value="10"></el-option>
            <el-option label="直充" :value="20"></el-option>
            <el-option label="吃喝卡券" :value="30"></el-option>
            <el-option label="电费" :value="40"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showSetCommission = !1">取 消</el-button>
        <el-button type="primary" @click="comfirmSetCommission">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="手动激励" :visible.sync="showEncourage" width="650px">
      <el-form ref="form" label-width="90px">
        <el-form-item label="sn">
          <el-input v-model="sn"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showEncourage = !1">取 消</el-button>
        <el-button type="primary" @click="comfirmEncourage">确 定</el-button>
      </span>
    </el-dialog>
  </el-main>
</template>

<script>
import { getDateformat } from '@/util/getDate';
import Paging from '@/components/paging';
import _ from 'lodash';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      searchForm: {
        page: 1,
        rows: 10,
        status: '',
        order_sn: '',
        sid: '',
        start_time: '',
        end_time: '',
        act_id: '',
      },
      id: '',
      list: [],
      total_number: 0,
      category: [],
      showSetCommission: !1,
      showEncourage: !1,
      sn: '',
      product_type: '',
      SetCommission_type: 1,
    };
  },
  created() {
    this.getList();
    this.$axios.post(this.$api.serviceProvider.trilateralCategory, { rows: 10000 }).then(res => {
      if (res.code == 0) {
        this.category = res.result.list;
      }
    });
  },
  methods: {
    getDateformat(val) {
      return getDateformat(val);
    },
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
      } else {
        this.searchForm.page = val;
      }
      this.getList();
    },
    search() {
      this.searchForm.page = 1;
      this.getList();
    },
    clearSearch() {
      this.searchForm = {
        page: 1,
        rows: 10,
        status: '',
        order_sn: '',
        sid: '',
        start_time: '',
        end_time: '',
        act_id: '',
      };
      this.getList();
    },
    setCommission(id, type) {
      this.showSetCommission = !0;
      this.id = id;
      this.sn = '';
      this.product_type = '';
      this.SetCommission_type = type;
    },
    setEncourage(id) {
      this.showEncourage = !0;
      this.id = id;
      this.sn = '';
    },
    comfirmEncourage() {
      if (!this.sn) return this.$message.warning('请填写sn');
      this.$axios
        .post(this.$api.serviceProvider.Encourage, {
          id: this.id,
          sn: this.sn,
        })
        .then(res => {
          if (res.code == 0) {
            this.$message.success('处理成功');
            this.showEncourage = !1;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    comfirmSetCommission() {
      if (!this.sn) return this.$message.warning('请填写sn');
      if (!this.product_type && this.SetCommission_type == 1) return this.$message.warning('请选择类型');
      let data = {
        id: this.id,
        sn: this.sn,
      };
      if (this.SetCommission_type == 1) data.product_type = this.product_type;
      this.$axios.post(this.$api.serviceProvider[this.SetCommission_type == 1 ? 'TelCommission' : 'TelCommission2'], data).then(res => {
        if (res.code == 0) {
          this.$message.success('处理成功');
          this.showSetCommission = !1;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getList() {
      let searchForm = _.cloneDeep(this.searchForm);
      if (searchForm.start_time) {
        searchForm.end_time = searchForm.start_time[1] / 1000;
        searchForm.start_time = searchForm.start_time[0] / 1000;
      } else {
        delete searchForm.start_time;
        delete searchForm.end_time;
      }
      if (!searchForm.sid) delete searchForm.sid;
      if (!searchForm.order_sn) delete searchForm.order_sn;
      if (!searchForm.act_id) delete searchForm.act_id;
      this.$axios.post(this.$api.serviceProvider.trilateralOrder, searchForm).then(res => {
        if (res.code == 0) {
          this.list = res.result.list;
          this.total_number = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
}
</style>